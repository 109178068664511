import PropTypes from "prop-types";
import { Spinner } from "react-redux-spinner";
import Routes from "./../../../../common/config/routes";
import TopBar from "./../TopBar";
import Menu from "./../Menu";
import Content from "./../Content";
import Loader from "./../Loader";
import LayoutState from "./../LayoutState";
import BusinessesModal from "./../BusinessesModal";
import { enquireScreen, unenquireScreen } from "enquire-js";
import { ContainerQuery } from "react-container-query";
import classNames from "classnames";
import AntLayout from "antd/lib/layout/layout";
import * as React from "react";
import "./index.scss";
import AppUpdateNotification from "../AppUpdateNotification";
import SelectBusinessTypeModal from "../SelectBusinessTypeModal";
import MfaAppNotification from "../MfaAppNotification";
import mixpanel from "mixpanel-browser";
import config from "../../../config";
const AntContent = AntLayout.Content;
const AntHeader = AntLayout.Header;

// Near entry of your product, init Mixpanel
mixpanel.init(config.mixpanel.MIXPANEL_TOKEN, {
  track_pageview: "url-with-path",
  persistence: "localStorage",
});

const query = {
  "screen-xs": {
    maxWidth: 575,
  },
  "screen-sm": {
    minWidth: 576,
    maxWidth: 767,
  },
  "screen-md": {
    minWidth: 768,
    maxWidth: 991,
  },
  "screen-lg": {
    minWidth: 992,
    maxWidth: 1199,
  },
  "screen-xl": {
    minWidth: 1200,
    maxWidth: 1599,
  },
  "screen-xxl": {
    minWidth: 1600,
  },
};

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

let contentBuffer = {
  pathName: null,
  content: null,
  containerExtraClass: null,
};

class Layout extends React.Component {
  static childContextTypes = {
    getContentBuffer: PropTypes.func,
    setContentBuffer: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    this.enquireHandler = enquireScreen((mobile) => {
      if (this.state.isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler);
  }

  getChildContext() {
    return {
      getContentBuffer: () => contentBuffer,
      setContentBuffer: ({ pathName, content, breadcrumb, containerExtraClass }) =>
        (contentBuffer = {
          pathName,
          content,
          breadcrumb,
          containerExtraClass,
        }),
    };
  }

  render() {
    const isMobile = !!this.state.isMobile;
    const childContext = this.getChildContext();
    const { containerExtraClass } = childContext && childContext.getContentBuffer();

    return (
      <ContainerQuery query={query}>
        {(params) => (
          <div className={`${containerExtraClass || ""} ${classNames(params)}`}>
            <AntLayout>
              <LayoutState />
              <Loader />
              <Spinner config={{}} />
              <Routes />

              <AntLayout className="monnify-main-content">
                <div className="greenBar" />
                <Menu isMobile={isMobile} />
                <section style={{ width: "100%" }}>
                  <AntHeader>
                    <TopBar />
                  </AntHeader>

                  <AntContent className="monnify-main-content-wrap">
                    <MfaAppNotification />
                    <AppUpdateNotification />
                    <BusinessesModal />
                    <SelectBusinessTypeModal />
                    <Content />
                  </AntContent>
                </section>
              </AntLayout>
            </AntLayout>
          </div>
        )}
      </ContainerQuery>
    );
  }
}

export default Layout;
